import { Injectable } from '@angular/core';
import {Config} from '../config';
import {Bulletin} from '../models/bulletins';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BulletinsService {

	private config = new Config();
	private httpUrl = `${this.config.url}/epsw/bulletin`;
	constructor(private http: HttpClient) { }

	getAllBulletin(){
		return this.http.get<Bulletin[]>(this.httpUrl + '/' + 'getAllBulletin');
	}

	createBulletin(newBulletin: Bulletin) {
		return this.http.post<Bulletin>(this.httpUrl+'/saveBulletin',newBulletin);
	}

	updateBulletin(id: any, updateBulletin: Bulletin) {
		return this.http.put<Bulletin>(this.httpUrl + '/updateBulletin/' +  id, updateBulletin);
	}

	findByBulletinId(id: number) {
		return this.http.get<Bulletin>(this.httpUrl+ '/getByBulletinId/' + id);
	}

	findBulletinByCurrentDate(): Observable<Bulletin[]> {
		return this.http.get(this.httpUrl + '/getAllBulletinByCurrentDate').pipe(map((bulletins: Bulletin[]) => {
			return bulletins.map(bulletin => {
				return new Bulletin(bulletin);
			});
		}));
	}

	deleteBulletin(id: number) {
		return this.http.delete<boolean>(this.httpUrl+ '/deleteBulletin/' + id);
	}
}
