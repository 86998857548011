import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Config} from '../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PpapPhaseQuestion} from '../models/ppap-phase-question';
import { PswResponse } from '../models/response/PswResponse';

@Injectable({
  providedIn: 'root'
})
export class PpapPhaseQuestionService {

 	private config = new Config();
	private httpUrlAssessmentQns = `${this.config.url}/epsw/phaseQuestions`;
	constructor(private http: HttpClient) { }

	getSectionData(phaseOption: any): Observable<any> {
		const params = new HttpParams().set('phaseOption',phaseOption);
		return this.http.get<any>(this.httpUrlAssessmentQns+'/sectionValue',{params});
	}

	getAllData() {
		return this.http.get<any[]>(this.httpUrlAssessmentQns+'/getAllData');
	}

	saveData(ppapPhaseQuestion: PpapPhaseQuestion) {
		return this.http.put<any>(this.httpUrlAssessmentQns+'/saveQuestions', ppapPhaseQuestion,{responseType:'json'});
	}

	deleteData(questionId: number){
		return this.http.delete<PswResponse>(this.httpUrlAssessmentQns+'/deleteQuestion/'+questionId,{responseType:'json'});
	}

	getSectionId(phaseValue: any, sectionValue: any): Observable<any> {
		const params = new HttpParams().set('phaseValue',phaseValue.name).set('sectionValue',sectionValue);
		return this.http.get<any>(this.httpUrlAssessmentQns+'/getSectionId',{params});
	}

	updateData(assessmentQuestion: PpapPhaseQuestion): Observable<any> {
		return this.http.put<any>(this.httpUrlAssessmentQns+'/updateQuestion/'+assessmentQuestion.questionId, assessmentQuestion);
	}
}
