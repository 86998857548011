import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';

interface DesignRecords {
	id: string;
	ppap: string;
	asstppap: string;
	quest: string;
	status: string;
	docSubmitted: string;
	proprietary: string;
	supplier: string;
	rejected: string;
	pdremarks: string;
}

@Component({
	selector: 'authorized-engineering',
	templateUrl: './authorized-engineering.component.html',
	styleUrls: ['./authorized-engineering.component.scss']
})
export class AuthorizedEngineeringComponent implements OnInit {

	designRecord: DesignRecords[];
	statuses: SelectItem[];
	clonedProducts: { [s: string]: DesignRecords; } = {};
	question: SelectItem[];
	rejected: SelectItem[];
	proprietary: SelectItem[];

	constructor() {
	}

	ngOnInit(): void {
		this.designRecord = [
			{
				id: '1',
				ppap: '1',
				asstppap: 'Is an SREA Required and Approved?',
				quest: '',
				status: '',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: ''
			},
			{
				id: '2',
				ppap: '2',
				asstppap: 'Is a WERS Alert Required and Approved?',
				quest: '',
				status: '',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: ''
			}
		]

		this.statuses = [{label: '', value: ''},{label: 'N/A', value: 'N/A'}, {label: 'OK', value: 'OK'},
			{label: 'NOK', value: 'NOK'}]
		this.question = [{label: '', value: ''}, {label: 'YES', value: 'YES'}, {label: 'NO', value: 'NO'}]
		this.rejected = [{label: '', value: ''}, {label: 'YES', value: 'YES'}, {label: 'NO', value: 'NO'}]
		this.proprietary = [{label: '', value: ''},{label: 'Proprietary', value: 'Proprietary'}]
	}

	getColor(mycolor: string): string {
		switch (mycolor) {
			case '-/-': return 'yellow';
			case 'OK':	return 'lightgreen';
			case 'N/A':	return 'Orange';
			case 'NOK':	return 'red';

		}
	}

}
