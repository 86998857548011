import {
	Component,
	OnInit,
	VERSION
} from '@angular/core';
import {environment} from '../environments/environment';
import {
	NavigationEnd,
	Router
} from '@angular/router';
import {filter} from 'rxjs/operators';
import {SpinnerService} from './services/spinner/spinner.service';
import {UserIdService} from './services/user-id.service';
import {ApsService} from './services/aps.service';
import {Bulletin} from './models/bulletins';
import {BulletinsService} from './services/bulletins.service';
import {MessageService} from 'primeng/api';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	userName;
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	/** constructor for setting up DI in this component */

	bulletins: Bulletin[];
	constructor(private userIdService: UserIdService,private readonly router: Router,
				private spinnerService: SpinnerService,private apsService: ApsService,private bulletinService:BulletinsService,
	private messageService:MessageService) {
	}
	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {
		this.userIdService.userNameSubject.subscribe((value: string) => {
			this.userName = value;
		});
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
				this.getAllBulletinByCurrentDate();
			});

		// this.apsService.retrieveAttributes().subscribe( async attributes => {
		// 	const attributeString = JSON.stringify(attributes);
		// 	console.log('User Role::' + JSON.parse(attributeString).userRole);
		// 	// await sessionStorage.setItem('navUserRole', JSON.parse(attributeString).userRole);
		// 	// alert(JSON.parse(attributeString).userRole)
		// });
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string) {
		if(!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}

	// Displays spinner for APS API Call
	checkSpinner() {
		return this.spinnerService.spinner.value;
	}

	checkFamilySpinner() {
		return this.spinnerService.familySpinner.value;
	}

	getAllBulletinByCurrentDate() {
		this.bulletinService.findBulletinByCurrentDate().subscribe(
			(resp) => {
				this.bulletins = resp;
			},
				(error) => {
					console.log('Error in retriving all current bulletin ' + error);
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail:
							'Error in getting all current bulletin\n' +
							JSON.stringify(error.message),
						life: 2000
					});
				}
			);
	}
}
