import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BulletinsService} from '../../../services/bulletins.service';
import {MessageService} from 'primeng/api';
import {Bulletin, emptyBulletin} from '../../../models/bulletins';

@Component({
	selector: 'create-bulletins',
	templateUrl: './create-bulletins.component.html',
	styleUrls: ['./create-bulletins.component.scss']
})
export class CreateBulletinsComponent implements OnInit {
	mode: string;
	displayName: string;
	bulletin: Bulletin = new Bulletin(null);
	startDateDisplay: Date;
	endDateDisplay: Date;
	buttonName: string;
	disableCreateButton = false;
	bulletins: Bulletin[];
	date: Date;

	constructor(private activatedRoute: ActivatedRoute, private bulletinService: BulletinsService,
				private router: Router, private messageService: MessageService) {
	}

	ngOnInit(): void {
		this.retrieveRouteParameter();
		if (this.mode === 'create') {
			this.initializeScreen();
		} else if (this.mode === 'edit') {
			this.findByBulletinId(this.bulletin.bulletinId);
		}
		this.validateCreateButton();
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.bulletin.bulletinId = Number(params.get('id'));
			if (this.mode === 'create') {
				this.displayName = 'Create New Bulletin';
				this.buttonName = 'Create New';
			} else if (this.mode === 'edit') {
				this.displayName = 'Edit Bulletin';
				this.buttonName = 'Update';
			}
			console.log('this.displayName ' + this.displayName);
		});
	}

	private initializeScreen() {
		this.bulletin = new Bulletin(emptyBulletin);
	}

	onSubmit() {
		this.bulletin.startDate = this.startDateDisplay.toISOString();
		this.bulletin.endDate = this.endDateDisplay.toISOString();
		if (this.mode === 'create') {
			this.onCreateBulletin(this.bulletin);
		} else if (this.mode === 'edit') {
			this.onUpdateBulletin(this.bulletin);
		}
	}

	onCreateBulletin(newBulletin: Bulletin) {
		if (this.validateFields(newBulletin)) {
					this.bulletinService.createBulletin(newBulletin).subscribe(success => {
						this.messageService.add({
							severity: 'success',
							summary: 'New Bulletin has been created successfully.',
							life: 5000,
						});
						this.goToManageScreen();
					}, createError => {
						this.messageService.add({
							severity: 'error',
							summary: 'Error while creating new Bulletin.',
							life: 5000,
						});
					});
				}
		}

	private validateFields(bulletin: Bulletin): boolean {
		if ((bulletin.startDate != null && bulletin.endDate != null) && (bulletin.endDate) < (bulletin.startDate)) {
			this.messageService.add({
				severity: 'error',
				summary: 'End date should be greater then start date.',
				life: 2000,
			});
			return false;
		} else {
			return true;
		}
	}
	validateCreateButton() {
		if (this.bulletin.bulletinTitle && this.bulletin.bulletinTitle.trim() !== '' && this.bulletin.description &&
			this.bulletin.description.trim() !== '' && (this.startDateDisplay || this.bulletin.startDate) &&
			(this.endDateDisplay || this.bulletin.endDate)) {
			this.disableCreateButton = false;
		} else {
			this.disableCreateButton = true;
		}
	}

	goToManageScreen() {
		this.router.navigate(['/manage-bulletins']);
	}

	onCancel() {
		this.messageService.add({
			severity: 'info',
			summary: 'Operation Cancelled',
			life: 2000,
		});
		this.goToManageScreen();
	}

	onUpdateBulletin(updateBulletin: Bulletin) {
		if (this.validateFields(updateBulletin)) {
			this.bulletinService.updateBulletin(updateBulletin.bulletinId, updateBulletin).subscribe(success => {
				this.messageService.add({
					severity: 'success',
					summary: 'Bulletin has been modified successfully.',
					life: 2000,
				});
				this.goToManageScreen();
			}, updateError => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error while updating Bulletin',
					life: 2000,
				});
			});
		}
	}

	findByBulletinId(bulletinId: number) {
			this.bulletinService.findByBulletinId(bulletinId).subscribe(response => {
				this.bulletin = response;
				this.startDateDisplay = new Date(this.bulletin.startDate);
				this.endDateDisplay = new Date(this.bulletin.endDate);
			});
		}
}
