export class Bulletin {

	bulletinId: number;
	bulletinTitle: string;
	description: string;
	startDate: string;
	endDate: string;
	link: string;
	updatedBy: string;
	updatedDate: number;
	linksArray: string[];

	constructor(bulletin: Bulletin) {
		Object.assign(this, bulletin);
	}
}

export const emptyBulletin = {
	bulletinId: 0,
	bulletinTitle: '',
	description: '',
	startDate: '',
	endDate: '',
	link: '',
	updatedBy: '',
	updatedDate: 0,
	linksArray: ['']

};
