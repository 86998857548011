import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ExamplePageComponent} from './example-page/example-page.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {LoginComponent} from './oauth/login.component';
import {AuthGuardService} from './oauth/auth-guard.service';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import { EpswHomepageComponent } from './epsw-homepage/epsw-homepage.component';
import { DigitalPPAPPackageComponent } from './digital-ppap-package/digital-ppap-package.component';
import {PpapPhaseSectionComponent} from './master/ppap-phase-section/ppap-phase-section.component';
import {PpapPhaseQuestionComponent} from './master/ppap-phase-question/ppap-phase-question.component';
import {SupplierViewComponent} from './supplier-view/supplier-view.component';
import {PswPartFormComponent} from './supplier-view/psw-part-form/psw-part-form.component';
import {NoAccessComponent} from './no-access/no-access.component';
import { PrintPswFormComponent } from './supplier-view/psw-part-form/print-psw-form/print-psw-form.component';
import {HelpComponent} from './help/help.component';
import {ManageBulletinsComponent} from './master/bulletin/manage-bulletins/manage-bulletins.component';
import {CreateBulletinsComponent} from './master/bulletin/create-bulletins/create-bulletins.component';

const routes: Routes = [

	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		redirectTo: '/home-page',
		pathMatch: 'full',
		canActivate: [AuthGuardService]
	},
	{
		path: 'home-page',
		component: EpswHomepageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home-page/:id',
		component: EpswHomepageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'DigitalPPAPPackage',
		component: DigitalPPAPPackageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'PrintPswFormComponent',
		component: PrintPswFormComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'DigitalPPAPPackage/:id/:mode',
		component: DigitalPPAPPackageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'DigitalPPAPPackage/:id/:previousFlag/:mode',
		component: DigitalPPAPPackageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplierView',
		component: SupplierViewComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplierView/:id/:mode',
		component: SupplierViewComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'ppap-phase-assessment-section',
		component: PpapPhaseSectionComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'ppap-phase-assessment-question',
		component: PpapPhaseQuestionComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-bulletins',
		component: ManageBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'bulletin/create-bulletins/:mode/:id',
		component: CreateBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'example-page',
		component: ExamplePageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'epsw-form',
		component: PswPartFormComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'epsw-form/:id/:mode/:actionMode/:partId',
		component: PswPartFormComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'help',
		component: HelpComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'epsw-form/:id/:mode/:actionMode/:partId/:familyPswId',
		component: PswPartFormComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'epsw-form/:id/:mode/:actionMode',
		component: PswPartFormComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: '404', component: NotFoundComponent
	},
	{
		path: 'no-access', component: NoAccessComponent
	},
	{
		path: '**', redirectTo: '/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
