import {Component, Input, OnInit} from '@angular/core';
import {Bulletin} from '../../../models/bulletins';

@Component({
  selector: 'show-bulletins-dialog',
  templateUrl: './show-bulletins-dialog.component.html',
  styleUrls: ['./show-bulletins-dialog.component.scss']
})
export class ShowBulletinsDialogComponent implements OnInit {

	@Input() bulletins: Bulletin[];
	displayBulletins = false;
	bulletinsData: Bulletin[];
	constructor() { }
	ngOnInit(): void {
	}

	onShowBulletins() {
		this.displayBulletins = true;
		if (this.bulletins && this.bulletins.length > 0) {
			this.bulletinsData = this.bulletins;
			this.bulletinsData.forEach(bulletin => {
				if (bulletin.link && bulletin.link.trim() !== '') {
					bulletin.linksArray = bulletin.link.split(' ');
				}
			});
		}
		console.log('bulletins data length ' + this.bulletinsData.length);
	}

	onClose() {
		this.displayBulletins = false;
	}

	retrieveLink(link: any) {
		console.log('link',link);
		window.open(link, '_blank');
	}
}
