import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Config} from '../config';
import {PpapElementRecords} from '../models/ppap-element-records';
import {PpapPackageIdentifier} from '../models/ppap-package-identifier';
import {Observable} from 'rxjs';
import {PpapRequiredElement} from '../models/ppap-required-element';
import {PpapPackageParts} from '../models/ppap-package-parts';
import {LdapResponse} from '../models/ldap-response';
import { Tier2SubSupplier } from '../models/Tier2SubSupplier';
import {ColorPartsFamilyPsw} from '../models/color-parts-family-psw';
import {PswResponse} from '../models/response/PswResponse';

@Injectable({
	providedIn: 'root'
})
export class DigitalPpapPackageService {
	private config = new Config();
	private httpUrl = `${this.config.url}`;
	private httpUrlPpapElement = `${this.config.url}/epsw/phaseQuestions/sectionQuestionsList`;
	private httpUrlPpapIndividualAssessment = `${this.config.url}/epsw/ppapPackage/saveAssessment`;
	private httpUrlPpapElementSavedQuestion = `${this.config.url}/epsw/ppapPackage/PpapElementSavedQuestion`;
	private httpUrlPpapPackageSave = `${this.config.url}/epsw/ppapPackage/savePackage`;
	private httpUrlPpapPackageUpdate = `${this.config.url}/epsw/ppapPackage/postPackage`;
	private httpUrlPpapRequiredElements = `${this.config.url}/epsw/requiredElement/savePackageRequiredElement`;
	private httpUrlPpapRequiredElementsID = `${this.config.url}/epsw/requiredElement/getPackageRequiredElement`;
	private headers = new HttpHeaders({'Content-Type': 'application/json'});
	private httpUrlPpapPackageSaveQuestionStatus = `${this.config.url}/epsw/ppapPackage/PpapElementSavedQuestionStatus`;
	private httpUrlPpapElementSubmitCheck = `${this.config.url}/epsw/requiredElement/getPackagePpapElementSubmitCheck`;
	private httpUrlPpapElementS3Attachment = `${this.config.url}/api/v1/objectstorage/buckets/51942-pcf-ns-bd7fa697-24ca03-bucket01/files`;
	private httpUrlPpapElementGCPUploadSignedURL = `${this.config.url}/api/v1/objectstorage/uploadSignedUrl`;
	private httpUrlPpapElementGCPDownloadSignedURL = `${this.config.url}/api/v1/objectstorage/downloadSignedUrl`;
	private httpUrlPpapElementGCPDownloadSignedURLForZip = `${this.config.url}/api/v1/objectstorage/downloadSignedUrlListForZip`;
	private httpUrlPpapElementGCPDeleteFile = `${this.config.url}/api/v1/objectstorage/delete/fileName`;
	private httpUrlPpapElementS3Download = `${this.config.url}/api/v1/objectstorage/buckets/51942-pcf-ns-bd7fa697-24ca03-bucket01/files`;
	private httpUrlPpapElementS3Delete = `${this.config.url}/api/v1/objectstorage/buckets/51942-pcf-ns-bd7fa697-24ca03-bucket01/files`;
	private httpUrlPpapElementS3DeleteByName = `${this.config.url}/api/v1/objectstorage/buckets/51942-pcf-ns-bd7fa697-24ca03-bucket01/filesByName`;
	private httpUrlTier2ExcelUpload = `${this.config.url}/epsw/tier2/excelUpload`;
	private httpUrlTier2SampleExcelDownload = `${this.config.url}/epsw/tier2/sampleExcelDownload`;
	private httpUrlTier2ExcelDownload = `${this.config.url}/epsw/tier2/ExcelDownload`;
	private httpUrlTier2ExcelstratificationRetrieval = `${this.config.url}/epsw/tier2/subsupplierDetails`;
	private httpUrlTier2ExcelDelete = `${this.config.url}/epsw/tier2`;
	private httpUrlCriticalSubSuppliersSave = `${this.config.url}/epsw/tier2/saveCritical`;
	private httpUrlViewPackage = `${this.config.url}/api/v1/objectstorage/getViewPackage`;
	private httpUrlPpapPackageSubmitCheck = `${this.config.url}/epsw/ppapPackage/submitCheck`;
	private httpUrlPpapPartSave = `${this.config.url}/epsw/saveStatus`;
	private httpUrlApprovePpapPackage = `${this.config.url}/epsw/approvePpapPackage`;
	private httpUrlRejectPpapPackage = `${this.config.url}/epsw/rejectPpapPackage`;
	private httpUrlNeedClarificationPpapPackage = `${this.config.url}/epsw/needClarification/ppapPackage`;
	private httpUrlApprovePpapPackageForParts = `${this.config.url}/epsw/approvePpapPackageForParts`;
	private httpUrlRejectPpapPackageForParts = `${this.config.url}/epsw/rejectPpapPackageForParts`;
	private httpUrlNeedClarificationPpapPackageForParts = `${this.config.url}/epsw/needClarificationForParts/ppapPackage`;
	private httpUrlPpapPackageAssessmentResponse = `${this.config.url}/epsw/ppapPackage/PpapPackageAssessmentResponse`;
	private httpUrlPpapPackageAssessmentResponseValidation = `${this.config.url}/epsw/ppapPackage/PpapPackageAssessmentResponseValidation`;
	private httpUrlSaveStaPdCommentOnNext = `${this.config.url}/epsw/saveCommentsOnNextClick`;
	private httpUrlPartsIdentifierData = `${this.config.url}/epsw/ppapPackage/getPartsIdentifierData`;
	private httpUrlProgramCodeCopyData = `${this.config.url}/epsw/ppapPackage/getProgramCodeCopyData`;
	private httpUrlPartNumberListData = `${this.config.url}/epsw/ppapPackage/getPartNumberListData`;
	private httpUrlRequiredElementCopyData = `${this.config.url}/epsw/ppapPackage/getRequiredElementsCopyData`;
	private httpUrlCopyData = `${this.config.url}/epsw/ppapPackage/copyData/`;
	private httpUrlGetSeparateProgram = `${this.config.url}/ppapPackageData/getSeparateProgram`;
	private httpUrlPpapPackageZipped = `${this.config.url}/api/v1/objectstorage/getPpapPackageZipped`;
	private httpUrlUpdateMailPriorityApprover = `${this.config.url}/epsw/updateMailPriorityApprover`;
	private httpUrlGetExcelData = `${this.config.url}/epsw/ppapPackage/getExcelSiteData`;

	constructor(private http: HttpClient) {
	}

	getPpapElementQuestion(ppapElementSectionElement: string, phaseType: string) {
		console.log('Ppap Element Question for Section Name : ' + ppapElementSectionElement + ' Phase Name : ' + phaseType);
		const params = new HttpParams().set('ppapElementSectionElement', ppapElementSectionElement).set('phaseType', phaseType);
		return this.http.get<any[]>(this.httpUrlPpapElement, {params});
	}

	savePpapElementRecords(ppapElementRecords: PpapElementRecords[]) {
		console.log('While Save the ppapElementRecords for individual Section : ' + ppapElementRecords);
		return this.http.post<any[]>(this.httpUrlPpapIndividualAssessment, ppapElementRecords, {responseType: 'json'})
	}

	getPpapElementSavedQuestion(sectionElement: string,  ppapPackageIdentifierID: number) {
		console.log('Ppap Element Question for Section Name : '+ sectionElement  +'ppapPackageIdentifierID :'+ppapPackageIdentifierID);
		const params = new HttpParams().set('sectionElement', sectionElement)
			.set('ppapPackageIdentifierID',String(ppapPackageIdentifierID));
		return this.http.get<any[]>(this.httpUrlPpapElementSavedQuestion  , {params});
	}

	saveData(ppapPackageIdentifierSave : PpapPackageIdentifier): Observable<any> {
		console.log('PPAP Package Save Call');
		return this.http.post<any>(this.httpUrlPpapPackageSave, ppapPackageIdentifierSave,{responseType:'json'});
	}

	getSignedUploadUrl(attachmentName: string): Observable<any> {
		return this.http.get(this.httpUrlPpapElementGCPUploadSignedURL+'/'+attachmentName,  { responseType: 'text' });
	}

	getSignedDownloadUrl(attachmentName: string): Observable<any> {
		return this.http.get(this.httpUrlPpapElementGCPDownloadSignedURL+'/'+attachmentName,  { responseType: 'text' });
	}

	getSignedDownloadUrlsForZip(identifierId: string): Observable<any> {
		return this.http.get(this.httpUrlPpapElementGCPDownloadSignedURLForZip+'/'+identifierId,  { responseType: 'json' });
	}
	deleteFile(attachmentName: string): Observable<any> {
		return this.http.delete<PswResponse>(this.httpUrlPpapElementGCPDeleteFile+'/'+attachmentName,  { responseType: 'json' });
	}

	uploadAdhocFile(data,file): Observable<any> {
		return this.http.put(data,file, {
			headers: new HttpHeaders({
				'Content-Type': file.type
			})
		});
	}

	updateData(ppapPackageIdentifierSave : PpapPackageIdentifier): Observable<any> {
		console.log('PPAP Package Update  Call')
		return this.http.post(this.httpUrlPpapPackageUpdate,ppapPackageIdentifierSave,{responseType:'json'});
	}

	savePpapElementRequired(ppapRequiredElements: PpapRequiredElement[]) {
		console.log('While Save the ppapRequiredElements for Package : '+ppapRequiredElements);
		return this.http.post<any[]>(this.httpUrlPpapRequiredElements, ppapRequiredElements,{responseType:'json'})
	}

	getPpapElementRequired(ppapPackageIdentifierID: number) {
		console.log('While get the ppapRequiredElements for Package ID : '+ppapPackageIdentifierID);
		console.log(this.httpUrlPpapRequiredElementsID + '/' + ppapPackageIdentifierID );
		return this.http.get<any[]>(this.httpUrlPpapRequiredElementsID  + '/' +ppapPackageIdentifierID )
	}
	//ForPARTS LIST

	getPrefixBaseSuffix(siteCode, phase, plan, ppapPhase, ppapOption,runningChange) {
		const params = new HttpParams().set('siteCode', siteCode)
			.set('phase',phase).set('plan',plan).set('ppapPhase',ppapPhase).set('ppapOption', ppapOption)
			.set('runningChange',runningChange);
		return this.http.get<any>(this.httpUrl + '/ppapPackageData/getPrefixBaseSuffix', {params,responseType: 'json'});
	}

	getPriorityStatus(prgmCode,siteCode, partBase) {
		console.log('111',prgmCode,siteCode,partBase)
		const params = new HttpParams()
			.set('prgmCode',prgmCode).set('siteCode',siteCode).set('partBase',partBase);
		console.log('complete')
		return this.http.get<any>(this.httpUrl + '/ppapPackageData/getPriorityStatus', {params,responseType: 'json'});
	}

	getPartsValueFromCmms(cmmsValue) {
		console.log('getPartsValueFromCmms',cmmsValue);
		const params = new HttpParams().set('cmmsValue', cmmsValue);
		return this.http.get<PswResponse>(this.httpUrl + '/ppapPackageData/getPartsValueFromCmms', {params});
	}

	savePart(ppapParts: PpapPackageParts) {
		console.log('Inside savePart',ppapParts);
		return this.http.post<PpapPackageParts>(this.httpUrl + '/ppapPackageData/savePart', JSON.stringify(ppapParts), {headers: this.headers});
	}
	saveParts(ppapParts: PpapPackageParts[]) {
		return this.http.post<PpapPackageParts>(this.httpUrl + '/ppapPackageData/saveParts', JSON.stringify(ppapParts), {headers: this.headers});
	}
	getAllData(packageID){
		const params = new HttpParams().set('packageID', packageID);
		return this.http.get<PswResponse>(this.httpUrl+'/ppapPackageData/getAllData',{params});
	}
	deletePart(packageId: number) {
		return this.http.delete<any>(this.httpUrl + '/ppapPackageData/deletePart/'+packageId, {responseType: 'json'});
	}

	savePpapElementRequiredStatus(ppapPackageIdentifierID: number) {
		console.log('While get the savePpapElementRequiredStatus for Package ID : '+ppapPackageIdentifierID);
		console.log(this.httpUrlPpapPackageSaveQuestionStatus + '/' + ppapPackageIdentifierID );
		return this.http.get<any[]>(this.httpUrlPpapPackageSaveQuestionStatus  + '/' +ppapPackageIdentifierID )
	}

	getPackagePpapElementSubmitCheck(ppapPackageIdentifierID: number) {
		console.log('While get the PpapElementSubmitCheck for Package ID : '+ppapPackageIdentifierID);
		console.log(this.httpUrlPpapElementSubmitCheck + '/' + ppapPackageIdentifierID );
		return this.http.get<any[]>(this.httpUrlPpapElementSubmitCheck  + '/' +ppapPackageIdentifierID )
	}

	putPpapElementResponseFile(formdata: FormData, attachmentName: string): Observable<any> {
		console.log(' Url for S3 : '+this.httpUrlPpapElementS3Attachment );
		return this.http.put(this.httpUrlPpapElementS3Attachment+'/'+attachmentName, formdata, { responseType: 'text' });
	}

	putPpapElementResponseFilesForUpload(formdata: FormData, attachmentName: string, currentFile): Observable<any> {
		console.log(' Url for S3 : '+this.httpUrlPpapElementS3Attachment );
		return this.http.put(this.httpUrlPpapElementS3Attachment+'/'+attachmentName + '/' + currentFile, formdata, { responseType: 'text' });
	}

	getPpapElementResponseFile(document: string) {
		console.log(' Url download for S3 : '+this.httpUrlPpapElementS3Download );
		return this.http.get(this.httpUrlPpapElementS3Download  + '/' +document +'/metadata',{ responseType: 'arraybuffer' });

	}

	deletePpapElementResponseFile(fileName: string) {
		console.log(' Url delete for S3 : '+this.httpUrlPpapElementS3Delete );
		return this.http.delete(this.httpUrlPpapElementS3Delete  + '/' +fileName +'/delete',{ responseType: 'text' });

	}

	deletePpapElementResponseFileByFileName(key, deleteFile) {
		console.log(' Url delete for S3 By Name: '+this.httpUrlPpapElementS3DeleteByName );
		return this.http.delete(this.httpUrlPpapElementS3DeleteByName  + '/' + deleteFile + '/' + key +'/delete',{ responseType: 'text' });

	}

	getViewPackage(ppapPackageIdentifierID: number) {
		console.log(' Url for View Package from S3 bucket : '+this.httpUrlViewPackage );
		return this.http.get(this.httpUrlViewPackage  + '/' +ppapPackageIdentifierID +
			'/51942-pcf-ns-bd7fa697-24ca03-bucket01',{ responseType: 'arraybuffer' });
	}
	searchFordBuyer(cdsid: string): Observable<LdapResponse[]> {
		return this.http.get<LdapResponse[]>(this.httpUrl + '/ldap/lookup/' + cdsid,{responseType: 'json'});
	}

	uploadTier2Excel(uploadTier2Excel: FormData) {
		console.log('Savinig Commodity Image New Service');
		return this.http.post(this.httpUrlTier2ExcelUpload, uploadTier2Excel);
	}

	downloadSampleTier2Excel() {
		this.http.get(this.httpUrlTier2SampleExcelDownload, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
			});
	}

	private downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'ePSW TIER 2 Supplier List.xlsm';
		anchor.href = url;
		anchor.click();
	}

	downloadTier2Excel(ppapPackageIdentifierID: string) {
		this.http.get(this.httpUrlTier2ExcelDownload + '/' + ppapPackageIdentifierID , {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
			});
	}

	getExcelData() {
		//const params = new HttpParams().set('siteCodeList',siteCodeList);
		this.http.get(this.httpUrlGetExcelData, {responseType:'blob'})
			.subscribe((response)=> {
				this.downloadExcelFile(response,'text/csv')
			});
	}

	retrieveTier2SubSupplierDetails(ppapPackageIdentifierID: string) {
		console.log('Stratification service call');
		return this.http.get<PswResponse>(this.httpUrlTier2ExcelstratificationRetrieval  + '/' +ppapPackageIdentifierID )
	}

	deleteUploadedTier2Detail(ppapPackageIdentifierID: string) {
		console.log('Delete Tier2 Details service call');
		return this.http.delete<any>(this.httpUrlTier2ExcelDelete  + '/' +ppapPackageIdentifierID +
			'/51942-pcf-ns-bd7fa697-24ca03-bucket01' );
	}

	saveTier2Details(criticalSubSuppliersSave: Tier2SubSupplier[]) {
		console.log('saveTier2Details service call');
		return this.http.post<any[]>(this.httpUrlCriticalSubSuppliersSave, criticalSubSuppliersSave,{responseType:'json'})
	}

	checkIfPdStaCdsidSaved(ppapPackageIdentifierID: number) {
		return this.http.get<PswResponse>(this.httpUrlPpapPackageSubmitCheck  + '/' +ppapPackageIdentifierID);
	}

	savePartsStatus(ppapPartsArray: any[],isSubmitted,pdStaApprovalRequiredL1) {
		const params = new HttpParams().set('pdStaApprovalRequiredL1', pdStaApprovalRequiredL1);
		return this.http.put(this.httpUrlPpapPartSave+'/'+isSubmitted, ppapPartsArray,{params});
	}

	updateMailPriorityApprover(ppapPackageIdentifierEntityOld: any[],isSubmitted) {
		return this.http.put(this.httpUrlUpdateMailPriorityApprover+'/'+isSubmitted,
			ppapPackageIdentifierEntityOld);
	}

	checkIfTier2PPAPPackageSaved(ppapPackageIdentifierID: number) {
		return this.http.get<any>(this.httpUrlTier2ExcelUpload + '/' + ppapPackageIdentifierID);
	}
	approvePpapPackage(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlApprovePpapPackage,ppapPartsArray);
	}

	rejectPpapPackage(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlRejectPpapPackage, ppapPartsArray);
	}

	needsClarification(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlNeedClarificationPpapPackage, ppapPartsArray);
	}

	approvePpapPackageForParts(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlApprovePpapPackageForParts,ppapPartsArray);
	}

	rejectPpapPackageForParts(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlRejectPpapPackageForParts, ppapPartsArray);
	}

	needsClarificationForParts(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlNeedClarificationPpapPackageForParts, ppapPartsArray);
	}

	findAllPpapAssessmentResponse(ppapPackageIdentifierId: number) {
		return this.http.get<PpapElementRecords[]>(this.httpUrlPpapPackageAssessmentResponse + '/' +ppapPackageIdentifierId);
	}

	sectionNCValidation(ppapPackageIdentifierID: number) {
		return this.http.get<any[]>(this.httpUrlPpapPackageAssessmentResponseValidation + '/' +ppapPackageIdentifierID);
	}

	saveStaPdCommentsOnNext(ppapPartsArray: PpapPackageParts[]) {
		return this.http.put(this.httpUrlSaveStaPdCommentOnNext,ppapPartsArray);
	}

	getPartsIdentifierData(siteCode,phase) {
		const params = new HttpParams().set('siteCode',siteCode).set('phase', phase);
		return this.http.get<any[]>(this.httpUrlPartsIdentifierData + '/',{params,responseType:'json'});
	}

	getProgramCodeCopyData(ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<PswResponse>(this.httpUrlProgramCodeCopyData+'/',{params,responseType:'json'});
	}

	copyData(packageIdentifierID,ppapPackageIdentifierId,dataCheckList,copyFromPartNumbers,copyFromProgramCode,bucketName) {
		const params = new HttpParams()
			.set('packageIdentifierID',packageIdentifierID)
			.set('copyFromPartNumbers',copyFromPartNumbers)
			.set('copyFromProgramCode',copyFromProgramCode)
			.set('bucketName',bucketName)
		return this.http.post<any>(this.httpUrlCopyData +ppapPackageIdentifierId ,dataCheckList,{params,responseType:'json'});
	}

	getPartNumbersList(ppapPackageIdentifierId, programCode) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId)
			.set('programCode',programCode);
		return this.http.get<PswResponse>(this.httpUrlPartNumberListData+'/',{params,responseType:'json'});
	}

	getRequiredElementsCopyData(ppapPackageIdentifierId,copyFromProgramCode,copyFromPartNumbers) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId)
			.set('copyFromProgramCode', copyFromProgramCode).set('copyFromPartNumbers',copyFromPartNumbers)
		return this.http.get<PswResponse>(this.httpUrlRequiredElementCopyData+'/',{params,responseType:'json'});
	}

	getSeparateProgram() {
		console.log('getSeparateProgram Service Call');
		return this.http.get<any>(this.httpUrlGetSeparateProgram);
	}
	//Added for US3832060
	deletePpapPart(ppapPackagePartsEntityID: number, ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.delete<any>(this.httpUrl + '/ppapPackageData/deletePpapPart/'+ppapPackagePartsEntityID, {params,responseType: 'json'});
	}
	getAllPartsData(noPartPrefix, noPartBase, noPartSuffix,ppapPackageIdentifierId) {
		const params = new HttpParams().set('noPartPrefix',noPartPrefix)
			.set('noPartBase',noPartBase).set('noPartSuffix',noPartSuffix)
			.set('noPartBase',noPartBase).set('ppapPackageIdentifierId',ppapPackageIdentifierId);
		// return this.http.get<any[]>(this.httpUrl+'/ppapPackageData/getAllPartsData',{params,responseType: 'json'});
		return this.http.get<PswResponse>(this.httpUrl+'/ppapPackageData/getAllPartsData',{params,responseType: 'json'});
	}

	deletePackage(ppapPackageIdentifierID: number) {
		return this.http.delete<any>(this.httpUrl + '/ppapPackageData/deletePackage/'+ppapPackageIdentifierID, {responseType: 'json'});
	}

	getColorPartsFamilyPswListFromUnfilteredTable(partNumberDetail) {
		return this.http.put<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/getColorPartsFromCmms',partNumberDetail, {responseType: 'json'});
	}

	saveColorParts(colorPartsList: ColorPartsFamilyPsw[]) {
		return this.http.post<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/saveColorParts',colorPartsList, {responseType: 'json'});
	}

	getColorPartsFamilyPswList(partNumberDetail) {
		return this.http.post<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/getColorPartsByPartsDetails', partNumberDetail, {responseType: 'json'});
	}

	deleteColorParts(colorParts: ColorPartsFamilyPsw) {
		return this.http.delete<PswResponse>(this.httpUrl + '/epsw/colorPartsFamilyPsw/deleteColorPart/'+colorParts.colorPartsFamilyPswId, {responseType: 'json'});
	}

	addNewPart(colorPartNumbers,ppapPackagePartsEntityID) {
		return this.http.post<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/addColorPart'+'/'+colorPartNumbers+'/'+ppapPackagePartsEntityID, {responseType: 'json'});
	}

	getColorPartsByPpapPartIds(ppapPartIds) {
		return this.http.get<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/getFromColorPartsByPartId/'+ppapPartIds, {responseType: 'json'});
	}

	getColorPartsByPpapPartIdsAndSiteCode(ppapPartIds, siteCode) {
		return this.http.get<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/getFromColorPartsByPartIdAndSiteCode/'+ppapPartIds+'/'+siteCode, {responseType: 'json'});
	}

	getAllColorPartsFamilyPswList() {
		return this.http.get<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/getColorParts', {responseType: 'json'});
	}

	getZippedDownloadPackage(ppapPackageIdentifierID) {
		console.log(' Url download for S3 : '+this.httpUrlPpapPackageZipped);
		return this.http.get(this.httpUrlPpapPackageZipped  + '/' +ppapPackageIdentifierID +
			'/51942-pcf-ns-bd7fa697-24ca03-bucket01',{ responseType: 'arraybuffer' });
	}

	retrievefromUnfilteredAndSaveGetFromColorPartsFamilyPswList(partNumberDetail) {
		return this.http.post<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/retrieveUnfilteredAndSaveColorParts/',partNumberDetail, {responseType: 'json'});
	}

	retrievefromUnfilteredAndSaveGetFromColorPartsFamilyPswListWithSiteCode(partNumberDetail, siteCode) {
		return this.http.post<ColorPartsFamilyPsw[]>(this.httpUrl + '/epsw/colorPartsFamilyPsw/retrieveUnfilteredAndSaveColorPartsWithSiteCode/' + siteCode, partNumberDetail, {responseType: 'json'});
	}

	private downloadExcelFile(response: Blob, type: string) {
		const blob = new Blob([response], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'ePSW Report Download.xlsm';
		anchor.href = url;
		anchor.click();

	}
}
