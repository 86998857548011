export class UserLoginDetail {
	userLoginDetailId: number
	userCDSID: string
	userMail: string
	userName: string
	userRole: string
	loginDate: string
	createdBy: string
	createdOn: Date
	lastUpdatedBy: string
	lastUpdatedOn: Date

	constructor(userLoginTracker : UserLoginDetail) {
		Object.assign(this,userLoginTracker);
	}
}
