import { Injectable } from '@angular/core';
import {Config} from '../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserLoginDetail} from '../models/UserLoginDetail';

@Injectable({
	providedIn: 'root'
})
export class UserLoginDetailService {
	private config = new Config();
	private httpUrlUserTracker = `${this.config.url}/epsw/userLoginDetails`;
	constructor(private http: HttpClient) { }

	saveUserDetails(userLoginDetail:UserLoginDetail):Observable<any> {
		return this.http.post(this.httpUrlUserTracker+'/saveUserDetail', userLoginDetail, {responseType:'json'} );
	}
}
