import {PpapPhaseQuestion} from './ppap-phase-question';

export class PpapPhaseSection {

	sectionId: number;
	phase: string;
	sectionName: string;
	sortSequence: number;
	isActive: string;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;

	constructor(ppapPhaseSection: PpapPhaseSection) {
		Object.assign(this, ppapPhaseSection);
	}

}
